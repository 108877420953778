<script>
import { SuawInputGroup, SuawButton } from "@suaw/suaw-component-library";
export default {
  name: "EventEditFormButtons",
  components: { SuawInputGroup, SuawButton },
  props: {
    isCancelled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCancelClicked() {
      this.$emit("cancel");
    },
    onUncancelClicked() {
      this.$emit("uncancel");
    },
    onBackClicked() {
      this.$emit("back");
    },
    onConfirmClicked() {
      this.$emit("confirm");
    }
  }
}
</script>

<template>
  <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true">
    <SuawButton v-if="isCancelled" class="suaw-button--pull-left" size="large" type="success-light" button-text="Reinstate Cancelled Event" @click="onUncancelClicked" />
    <SuawButton v-else class="suaw-button--pull-left" size="large" type="critical-light" button-text="Cancel Event" @click="onCancelClicked" />
    <SuawButton size="large" type="ghost-outline" button-text="Go Back" @click="onBackClicked" />
    <SuawButton size="large" type="primary" button-text="Confirm Changes" @click="onConfirmClicked" />
  </SuawInputGroup>
</template>
